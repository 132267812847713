import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', loadComponent: () => import('./components/home/home.component').then(h => h.HomeComponent)},
  { path: 'about-us', loadComponent: () => import('./components/about-us/about-us.component').then(a => a.AboutUsComponent), title: 'About Us' },
  { path: 'IOT-solutions', loadComponent: () => import('./components/devices/devices.component').then(d => d.DevicesComponent), title: 'IOT-solutions' },
  { path: 'device-detail/:deviceName', loadComponent: () => import('./components/devices/device-detail/device-detail.component').then(d => d.DeviceDetailComponent), title: 'Device Detail' },
  { path: 'thankyou', loadComponent: () => import('./components/devices/thankyou/thankyou.component').then(t => t.ThankyouComponent), title: 'Thank You' },
  { path: 'industries', loadComponent: () => import('./components/industries/industries.component').then(i => i.IndustriesComponent), title: 'Industries' },
  { path: 'blog', loadComponent: () => import('./components/blog/blog.component').then(b => b.BlogComponent), title: 'Blog' },
  { path: 'careers', loadComponent: () => import('./components/careers/careers.component').then(c => c.CareersComponent), title: 'Careers' },
  { path: 'contact-us', loadComponent: () => import('./components/contact-us/contact-us.component').then(c => c.ContactUsComponent), title: 'Contact Us' },
  { path: 'hire-resources', loadComponent: () => import('./components/hire-resources/hire-resources.component').then(h => h.HireResourcesComponent), title: 'Hire Resources' },
  { path: 'privacy-cookie', loadComponent: () => import('./components/privacy-cookie-policy/privacy-cookie-policy.component').then(p => p.PrivacyCookiePolicyComponent), title: 'Privacy-Cookie' },
  { path: 'not-found', loadComponent: () => import('./components/not-found/not-found.component').then(n => n.NotFoundComponent), data: { hideHeaderFooter: true } },
  { path: '**', redirectTo: '/not-found' }
];
