<nav [ngClass]="{'fixed-top': isFixed}" class="navbar navbar-expand-xl navbar-light my-2" aria-label="Main navigation">
  <div class="container-xxl">
    <div class="navbar-brand"> <a routerLink=""></a></div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="main_nav">
      <ul class="navbar-nav menu-bg-inner">
        <li class="nav-item" *ngFor="let menuItem of menuItems; let i = index">
          <a class="nav-link" [routerLink]="menuItem.route" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{ menuItem?.name }}</a>
        </li>
      </ul>
      <div class="navbar-nav ms-auto">
        <a [routerLink]="'/hire-resources'" routerLinkActive="active" class="hire-resources">Hire Resources</a>
      </div>
    </div>
  </div>
</nav>