import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private subject = new Subject<{ isLoading: boolean }>();

  private renderer: Renderer2;
  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setLoaderShownProperty(isLoading: boolean): void {
    this.subject.next({ isLoading });
  }

  getLoaderShownProperty(): Observable<{ isLoading: boolean }> {
    return this.subject.asObservable();
  }
  
  getPDropdownData = (array: any, labelName: any, valueName: any) => {
    let returnArray: any = []
    array?.forEach((el: any) => {
      returnArray.push({
        label: el[labelName],
        value: el[valueName],
      })
    });
    return returnArray;
  }

  smoothScrollToActiveTab(el: HTMLElement): number | null {
    const container = el.querySelector('.tabs ul');
    const activeTab:any = el.querySelector('.tabs ul li.active');

    if (container && activeTab) {
      const containerWidth = container.clientWidth;
      const activeTabWidth = activeTab.offsetWidth;
      const scrollTo = activeTab.offsetLeft - (containerWidth - activeTabWidth) / 2;

      this.renderer.setStyle(container, 'scrollBehavior', 'smooth');
      container.scrollLeft = scrollTo;

      // Reset scrollBehavior to auto for future scrolls
      setTimeout(() => {
        this.renderer.setStyle(container, 'scrollBehavior', 'auto');
      }, 500); // Match the duration with smooth scroll time

      return scrollTo; // Return the calculated scroll position
    }

    return null; // Return null if container or activeTab is not found
  }
}