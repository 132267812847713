import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { LoaderComponent, HeaderComponent, FooterComponent, CommonContactUsComponent, TruncatePipe, CustomDatePipe} from 'src/app/components/shared';

@NgModule({
  declarations: [
    LoaderComponent, 
    HeaderComponent, 
    FooterComponent, 
    CommonContactUsComponent,
    TruncatePipe,
    CustomDatePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MultiSelectModule,
    DropdownModule,
    ToastModule,
    TooltipModule
  ],
  providers: [],
  exports: [LoaderComponent, HeaderComponent, FooterComponent, CommonContactUsComponent,TruncatePipe,CustomDatePipe,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MultiSelectModule,
    DropdownModule,
    ToastModule,
    TooltipModule
  ]
})
export class SharedModule { }