<ng-container *ngIf="showHeaderAndFooter">
    <app-header></app-header>
</ng-container>

<app-loader [isLoading]="isLoading"></app-loader>
<router-outlet></router-outlet>
<p-toast></p-toast>

<ng-container *ngIf="showHeaderAndFooter">
    <app-footer></app-footer>
</ng-container>