import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  isFixed: boolean = false;
  // Listen for the window scroll event
  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Check if the scroll position is greater than 100 pixels
    this.isFixed = window.scrollY > 100;
  }
  menuItems = [
    { name: 'Home', route: '' },
    { name: 'About Us', route: '/about-us' },
    { name: 'IOT Solutions', route: '/IOT-solutions' },
    { name: 'Industries', route: '/industries' },
    // { name: 'Blog', route: '/blog' },
    // { name: 'Careers', route: '/careers' },
    { name: 'Contact Us', route: '/contact-us' }
  ];
}
