import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient,HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  constructor(private http: HttpClient) { }
  post(urlPath: string, body?: any): Observable<any> {
    return this.http.post(urlPath, body);
  }
  get(urlPath: string, params?: any): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get(urlPath, { params: httpParams });
  }
  getWithParams(urlPath: string, params?: any): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get(urlPath, { params: httpParams });
  }
  uploadCV(urlPath: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('cv', file);
    return this.http.post<any>(urlPath, formData);
  }
}