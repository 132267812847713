import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CommonService } from '../../app/common.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private commonService: CommonService, private messageService: MessageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.commonService.setLoaderShownProperty(true);
    const headers: HttpHeaders = new HttpHeaders({
      "Accept": "application/json"
    });
    request = request.clone({
      url: environment.apiKey + request.url,
      headers
    });
    return next.handle(request).pipe(
      map(response => this.successHandler(response)),
      catchError(err => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err?.error?.message || 'An error occurred' ,life: 30000});
        const error = err.error?.message || 'Unknown error';
        this.commonService.setLoaderShownProperty(false);
        return throwError(() => new Error(error));
      })
    );
  }

successHandler(response: HttpEvent<any>): HttpEvent<any> {
    if (response instanceof HttpResponse) {
      if (response.body?.status === false) {
        this.commonService.setLoaderShownProperty(false);
      }
    }
    return response;
  }
}