import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { CommonService } from './services';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  showHeaderAndFooter: boolean = true;
  isLoading: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,private commonService: CommonService,private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
      // Subscribe to router events to determine when to hide/show header and footer
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
        // Check the current route to decide whether to show header and footer
        this.showHeaderAndFooter = !this.activatedRoute.snapshot.firstChild?.data?.['hideHeaderFooter'];
        if (event instanceof NavigationEnd) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.updateCanonicalUrl(this.document.location.href);
        }
      });
    this.getLoader();
  }

  getLoader() {
    this.commonService.getLoaderShownProperty().subscribe(({ isLoading }) => {
      setTimeout(() => {
        this.isLoading = isLoading;
      }, 0);
    });
  }

  updateCanonicalUrl(url: string): void {
    let link: HTMLLinkElement | any = this.document.querySelector('link[rel="canonical"]');
    if (link) {
      this.renderer.setAttribute(link, 'href', url);
    } else {
      link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'canonical');
      this.renderer.setAttribute(link, 'href', url);
      this.renderer.appendChild(this.document.head, link);
    }
  }
}