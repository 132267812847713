<div class="get-in-touch">
    <div class="container px-5">
        <div class="row">
            <div class="col-md-9">
                <h3>Get in touch with us to learn more about how <span>Amber IoT can revolutionize your business</span>.
                    Connect with us on social media for more updates.</h3>
                <div class="contact" [routerLink]="['/contact-us', { IOT: 'office-locations'}]"><a>Contact Us</a></div>
            </div>
            <div class="col-md-3">
               <a [routerLink]="['/contact-us', { IOT: 'office-locations'}]" routerLinkActive="active"><img src="assets/img/common/get-in-couch.png" alt="get-in-couch" class="mw-90"></a>
            </div>
        </div>
    </div>
</div>
