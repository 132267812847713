import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent{
  isOpenCookie:boolean = false;
  showRocket: boolean = false;
  openCookie(){
    this.isOpenCookie = true;
  }
  accept(){
    this.isOpenCookie = false;
  }
  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (window.scrollY > 50) {
      this.showRocket = true;
    } else {
      this.showRocket = false;
    }
    const rocketElement = document.getElementById('rocketmeluncur');
    if (rocketElement) {
      if (this.showRocket) {
        rocketElement.style.display = 'block';
      } else {
        rocketElement.style.display = 'none';
      }
    }
  }
}