import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, maxLength: number = 10): string {
    if (!value || value.length <= maxLength) {
      return value;
    }
    return value.substring(0, maxLength) + '...';
  }
}

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  transform(value: string | null | undefined, format: string = 'dd MMM YYYY'): string {
    if (!value) { return '' }
    const parsedDate = new Date(value);
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(parsedDate, format) || '';
  }
}